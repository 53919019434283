import { Link } from '@remix-run/react';

export function ErrorMessage(props: {
  title: string;
  body: string;
  home?: string;
}): JSX.Element {
  return (
    <div className='w-3/4 md:w-1/2 xl:w-1/3 h-full flex flex-col items-center justify-center gap-3 text-white text-center'>
      <div className='text-2xl font-bold'>{props.title}</div>
      <div className='text-lg mb-5'>{props.body}</div>
      <Link to={props.home ?? '/home'}>
        <button type='button' className='btn btn-primary w-40 h-10'>
          Back Home
        </button>
      </Link>
    </div>
  );
}
